(function ($) {
    $.fn.addressfinder = function (
        postcode,
        houseNumber,
        houseNumberAddition,
        street,
        residence,
        province,
        postcodeNumberSelector,
        postcodeCharacterSelector,
        feedbackIndicators,
        loadingIcon,
        feedbackMsgHolder,
        addressBox,
        addressLines,
        transformFields
    ) {
        var element = $(this);
        var _endpoint = "/api/addressfinder/";

        var fFadeInValid, fFadeInInValid;
        var oIconValid = $(feedbackIndicators.filter(".valid"), element);
        var oIconInvalid = $(feedbackIndicators.filter(".invalid"), element);

        $(".js-province select.custom-select").on("change", (e) => {
            $(province, element).val(e.currentTarget.value);
        });

        function showMessage(message) {
            var msg = $(feedbackMsgHolder.find(":first-child"), element);

            message = message + " Voer straat- en plaatsnaam handmatig in hieronder.";

            msg.html(message);
            feedbackMsgHolder.removeClass("hidden");

            if (!transformFields) {
                $(street, element).removeAttr("readonly");
                $(residence, element).removeAttr("readonly");
                $(province, element).removeAttr("readonly");
            } else {
                $(street, element)
                    .attr("type", "text")
                    .parents(".obscured")
                    .first()
                    .removeClass("obscured")
                    .addClass("shown");
                $(residence, element)
                    .attr("type", "text")
                    .parents(".obscured")
                    .first()
                    .removeClass("obscured")
                    .addClass("shown");
                $(province, element)
                    .attr("type", "text")
                    .parents(".obscured")
                    .first()
                    .removeClass("obscured")
                    .addClass("shown");
            }

            $(street, element).one("focus", clearPostcodeErr);
            $(residence, element).one("focus", clearPostcodeErr);
            $(province, element).one("focus", clearPostcodeErr);
        }

        function refreshAddress() {
            oIconValid.removeClass("active").css("display", "none");
            oIconInvalid.removeClass("active").css("display", "none");

            if (addressBox != null) {
                $(addressLines, element).html("");
            }

            if (!transformFields) {
                $(street, element).attr("readonly", "readonly");
                $(residence, element).attr("readonly", "readonly");
                $(province, element).attr("readonly", "readonly");
            } else {
                $(street, element)
                    .attr("type", "hidden")
                    .parents(".shown")
                    .first()
                    .removeClass("shown")
                    .addClass("obscured");
                $(residence, element)
                    .attr("type", "hidden")
                    .parents(".shown")
                    .first()
                    .removeClass("shown")
                    .addClass("obscured");
                $(province, element)
                    .attr("type", "hidden")
                    .parents(".shown")
                    .first()
                    .removeClass("shown")
                    .addClass("obscured");
            }

            feedbackMsgHolder.addClass("hidden");

            var _postcode = $(postcode, element).val();

            if (postcodeNumberSelector || postcodeCharacterSelector) {
                _postcode =
                    $(postcodeNumberSelector, element).val() +
                    $(postcodeCharacterSelector, element).val();
            }

            var _houseNumber = $(houseNumber, element).val();
            var _houseAddition = $(houseNumberAddition, element).val();

            if (_houseNumber.length <= 0) {
                return;
            }

            // Make sure an addition is stripped off if it may have been added to the house number, and add it to the addition manually
            if (/[^\d]/.test(_houseNumber)) {
                var aHouseNrAdd = _houseNumber.match(/[^\d]+/gm);
                _houseAddition = aHouseNrAdd[0].trim();

                _houseNumber = _houseNumber.replace(/[^\d]/g, "");
            }

            if (addressBox != null) {
                $(addressBox, element).removeClass("hidden");
            }

            loadingIcon.css("display", "block");
            var fFadeInLoad = function () {
                loadingIcon.addClass("active");
                clearTimeout(fFadeInLoad);
            };
            setTimeout(fFadeInLoad, 0);

            var url =
                _endpoint +
                "?postcode=" +
                _postcode +
                "&houseNumber=" +
                _houseNumber +
                "&houseAddition=" +
                _houseAddition;

            $.getJSON(url, function (data) {
                loadingIcon.removeClass("active").css("display", "none");

                if (data) {
                    if (data.Success) {
                        var address = eval("(" + data.Data + ")");

                        if (address.houseNumberAddition != null) {
                            $(houseNumberAddition, element).val(address.houseNumberAddition);
                        }

                        $(street, element).val(address.street);
                        $(residence, element).val(address.city);

                        const provinces = {
                            Drenthe: "DR",
                            Flevoland: "FL",
                            Friesland: "FR",
                            Gelderland: "GE",
                            Groningen: "GR",
                            Limburg: "LI",
                            "Noord-Brabant": "NB",
                            "Noord-Holland": "NH",
                            Overijssel: "OV",
                            Utrecht: "UT",
                            Zeeland: "ZE",
                            "Zuid-Holland": "ZH",
                        };
                        $(province, element).val(provinces[address.province]);

                        province
                            .closest(".js-province")
                            .find(".js-province-select")
                            .addClass("hidden");

                        if (addressBox == null) {
                            oIconValid.css("display", "block");
                            fFadeInValid = function () {
                                oIconValid.addClass("active");
                                clearTimeout(fFadeInValid);
                            };
                            setTimeout(fFadeInValid, 0);
                        } else {
                            $(addressLines, element).html(
                                "" +
                                    '<p class="title"><strong>Adres</strong></p>' +
                                    "<p>" +
                                    address.street +
                                    " " +
                                    address.houseNumber +
                                    (address.houseNumberAddition != null
                                        ? address.houseNumberAddition
                                        : "") +
                                    "</p>" +
                                    "<p>" +
                                    address.postcode +
                                    " " +
                                    address.city +
                                    "</p>" +
                                    ""
                            );
                        }

                        return;
                    } else {
                        if (addressBox != null) {
                            $(addressLines, element).html("");
                        }

                        oIconInvalid.css("display", "block");
                        fFadeInInvalid = function () {
                            oIconInvalid.addClass("active");
                            clearTimeout(fFadeInInvalid);
                        };
                        setTimeout(fFadeInInvalid, 0);

                        showMessage(data.Message);
                    }
                } else {
                    if (addressBox != null) {
                        $(addressLines, element).html("");
                    }

                    oIconInvalid.css("display", "block");
                    fFadeInInvalid = function () {
                        oIconInvalid.addClass("active");
                        clearTimeout(fFadeInInvalid);
                    };
                    setTimeout(fFadeInInvalid, 0);

                    showMessage("Invalid data");
                }

                $(street, element).val("");
                $(residence, element).val("");
                $(province, element).val($(".js-province select.custom-select")[0].value);
                province.closest(".js-province").find(".js-province-select").removeClass("hidden");
            });
        }

        function clearPostcodeErr() {
            var msg = $(feedbackMsgHolder.find(":first-child"), element);
            msg.html("");
            feedbackMsgHolder.addClass("hidden");

            if (addressBox != null) {
                $(addressBox, element).addClass("hidden");
            }

            oIconInvalid.removeClass("active").css("display", "none");
        }

        $(postcode, element).on("blur", function () {
            // First make sure the zipcode is valid
            var oPostcode = $(this);
            var sPostcode = oPostcode.val();
            oPostcode.val(sPostcode.toUpperCase().replace(" ", ""));

            refreshAddress();
        });

        $(houseNumber, element).on("blur", refreshAddress);
        $(houseNumberAddition, element).on("blur", refreshAddress);

        if (postcodeNumberSelector) {
            $(postcodeNumberSelector, element).on("blur", function () {
                refreshAddress();
            });
        }

        if (postcodeCharacterSelector) {
            $(postcodeCharacterSelector, element).on("blur", function () {
                refreshAddress();
            });
        }
    };
})(jQuery);
